import React from 'react'
import styled from 'styled-components'

import Colors from 'theme/colors'
import { useText } from 'texts'

const StyledDefinition = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${({ isSubterm }) => !isSubterm ? '32px' : '12px'};
  padding-bottom: ${({ isSubterm }) => !isSubterm ? '32px' : '12px'};
  padding-left: ${({ isSubterm }) => !isSubterm ? '20%;' : null};
  padding-right: ${({ isSubterm }) => !isSubterm ? '20%;' : null};
  scroll-margin-top: 75px;
  a {
    font-weight: bold;
    color: #0086ff;
    text-decoration: none;
  }
  background-color: ${({ highlight, isSubterm }) => {
    if (isSubterm) return 'inherit'

    return highlight ? Colors.GRAY_100 : Colors.NEUTRAL_100
  }}
`

const Term = ({ children, term, isSubterm, highlight, termIndex }) => {
  const { t } = useText()
  let termString = `${termIndex}. ${t(term)}`

  return (
    <StyledDefinition highlight={highlight} isSubterm={isSubterm} id={term}>
      {
        isSubterm ?
          <div
            dangerouslySetInnerHTML={{
              __html: termString
            }}
          /> :
          <h3 className="text-primary-100">{termString}</h3>
      }
      {children}
    </StyledDefinition>
  )
}

export default Term
