export function extractTerms(terms) {
  function processSubterms(subterms) {
      return Object.keys(subterms).map((key) => {
          const subterm = subterms[key];
          if (subterm.subterms) {
              const processedSubterms = processSubterms(subterm.subterms);
              return {
                  tKey: key,
                  titleKey: "title",
                  subtermsKey: "subterms",
                  subterms: processedSubterms.length > 0 ? processedSubterms : []
              };
          } else {
              return key;
          }
      });
  }

  return Object.keys(terms).map((key) => {
      const term = terms[key];
      const processedSubterms = processSubterms(term.subterms);

      return {
          tKey: key,
          titleKey: "title",
          subtermsKey: "subterms",
          subterms: processedSubterms
      };
  });
}
